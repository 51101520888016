import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LibrarySource } from '../../../../api/gameTypes'
import { PopupActionMenu } from '../../../../common/components/PopupMenu/PopupActionMenu'
import { StatusPill } from '../../../../common/components/StatusPill/StatusPill'
import { getIcon } from '../../../../common/components/icons/utils'
import { Tooltip } from '../../../../common/components/tooltip/Tooltip'
import { GameAction, GameStatus, TGameCard } from '../../../../types/commonTypes'
import { getFocusableGameItemId } from '../../helpers'
import { GameMoreMenu } from '../GameMoreMenu'
import styles from './Card.module.css'
import { GameInfo } from './GameInfo'

type CardProps = {
  game: TGameCard
  maxWidth?: string
  minWidth?: string
  archivedAt?: string
  onGameAction: (action: GameAction, game: TGameCard) => void
  libraryMode?: boolean
  disabled?: boolean
  isSponsored?: boolean
  isSponsoredInstructorView?: boolean
}

export const Card: React.FC<CardProps> = ({
  game,
  maxWidth,
  minWidth,
  archivedAt,
  onGameAction,
  libraryMode,
  disabled,
  isSponsored = false,
  isSponsoredInstructorView = false,
}) => {
  const { t } = useTranslation()

  const { id, modifiedAt, gameName, thumbnail, status, secondaryStatus, exercisesCount, mapsCount } = game

  const cardId = getFocusableGameItemId(id)

  const internalOnAddClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    onGameAction(GameAction.IMPORT, game)
  }

  const internalOnDownloadClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    onGameAction(GameAction.DOWNLOADMODAL, game)
  }

  const internalOnCardClick = () => {
    onGameAction(GameAction.VIEW, game)
  }

  const onCardKeyDownInternal = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      e.key !== 'Enter' ||
      (e.target as EventTarget & { getAttribute: (attr: string) => string })?.getAttribute?.('id') !== cardId
    ) {
      return
    }
    e.preventDefault()
    onGameAction(GameAction.VIEW, game)
  }

  const handleMenuItemClick = (action: GameAction) => {
    onGameAction(action, game)
  }

  return (
    <div style={{ position: 'relative' }}>
      {disabled && <div className={styles.cardDisabledOverlay} />}
      <div
        className={classNames(
          styles.container,
          status === 'IN_PROGRESS' && game.librarySource !== 'SPONSORED' && styles.inProgressBorder,
        )}
        style={{
          maxWidth,
          minWidth,
        }}
        onClick={internalOnCardClick}
        role='button'
        tabIndex={0}
        onKeyDown={onCardKeyDownInternal}
        id={cardId}
      >
        {status === 'ARCHIVED' ? <div className={styles.cardArchivedOverlay} /> : null}
        <div className={styles.top} style={{ backgroundImage: `url('${thumbnail}')` }}>
          <StatusPill
            status={isSponsored || game.librarySource === 'SPONSORED' ? GameStatus.SPONSORED : status}
            variant={'card'}
            secondaryStatus={secondaryStatus}
          />
          <div>
            {isSponsored || (isSponsored && isSponsoredInstructorView) ? (
              <Tooltip tooltipContent={t('sponsored_games.download_game_tooltip', 'Download game')}>
                {(tooltipProps) => (
                  <button className={styles.cardButton} onClick={internalOnDownloadClick} {...tooltipProps}>
                    {isSponsoredInstructorView ? getIcon('plus') : getIcon('download')}
                  </button>
                )}
              </Tooltip>
            ) : game.librarySource !== LibrarySource.OWN && status !== 'EXPIRED' && !isSponsored ? (
              <Tooltip tooltipContent={t('game_info.import_game.tooltip', 'Create own copy')}>
                {(tooltipProps) => (
                  <button className={styles.cardButton} onClick={internalOnAddClick} {...tooltipProps}>
                    {getIcon('plus')}
                  </button>
                )}
              </Tooltip>
            ) : (
              <></>
            )}
            {status !== 'ARCHIVED' && game.librarySource === LibrarySource.OWN && !isSponsored && (
              <Tooltip tooltipContent={t('game_info.open_game_in_editor.tooltip', 'Open game in editor')}>
                {(tooltipProps) => (
                  <button
                    className={styles.cardButton}
                    onClick={() => onGameAction(GameAction.EDIT, game)}
                    {...tooltipProps}
                  >
                    {getIcon('text')}
                  </button>
                )}
              </Tooltip>
            )}
            <PopupActionMenu
              id={`${cardId}_moreMenuButton`}
              buttonClassName={styles.cardButton}
              buttonTooltip={t('game_info.context_menu.tooltip', 'More actions')}
            >
              <GameMoreMenu
                onMenuItemClick={handleMenuItemClick}
                libraryMode={libraryMode || game.librarySource !== LibrarySource.OWN}
                isExpired={game.expired}
                isSponsored={isSponsored}
                isSponsoredInstructorView={isSponsoredInstructorView}
              />
            </PopupActionMenu>
          </div>
        </div>
        <div className={styles.bot}>
          <h5 className={styles.gameTitle}>
            {gameName || t('game_info.missing_title_placeholder', 'Game title missing')}
          </h5>
          <GameInfo
            status={status}
            archivedAt={archivedAt}
            modifiedAt={modifiedAt}
            exercisesNumber={exercisesCount}
            mapsNumber={mapsCount}
            playersCount={game.playersCount}
            showModifiedAt={true}
            isSponsored={isSponsored}
          />
        </div>
      </div>
    </div>
  )
}
