import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { t } from 'i18next'
import 'mapbox-gl/dist/mapbox-gl.css'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MapType } from '../../api/gameTypes'
import { HelpMenu } from '../../composites/HelpMenu/HelpMenu'
import { GlobalNotification } from '../../composites/layout/GlobalNotification'
import { useChat } from '../../contexts/ChatContextProvider'
import { useConfirmation } from '../../contexts/ConfirmationContext'
import { useGame } from '../../contexts/GameContext'
import { useNotification } from '../../contexts/NotificationContext'
import { useUser } from '../../contexts/userContext'
import { routes } from '../../routes'
import { setShouldOpenWizardOnGameOpen, shouldOpenWizardOnGameOpen } from '../../util/editorPreference'
import { preventUserStartGame } from '../../util/game'
import styles from './GameEditor.module.css'
import { BadgeManagerModal } from './components/Badges/BadgeManagerModal/BadgeManagerModal'
import { BoardSection } from './components/BoardSection/BoardSection'
import { BoardsOverview } from './components/BoardSection/BoardsOverview/BoardsOverview'
import { EditorSidebar } from './components/EditorSidebar/EditorSidebar'
import { GameReport } from './components/GameReport/GameReport'
import { GameSettingsModal } from './components/GameSettings/GameSettingsModal'
import { Header } from './components/Header/Header'
import { animateNewlyAddedPin } from './components/Marker/TaskPinHelper'
import { QuickStartWizard } from './components/QuickStartWizard/QuickStartWizard'
import { ShareGameModal } from './components/ShareGame/ShareGameModal'
import { GameBoardSettings, GameForm, ShareGameForm, TabType } from './types'

type GameEditorProps = {
  initialGameId: number | null
  initialIsGameOpen: boolean
}

export const GameEditor: React.FC<GameEditorProps> = ({ initialGameId, initialIsGameOpen }) => {
  const { notifyError, notifySuccess, notifyDoorsAdded } = useNotification()
  const { isChatOpen } = useChat()
  const { requestConfirmation } = useConfirmation()
  const [activeBoardIndex, setActiveBoardIndex] = useState(0)
  const [isShareGameModalOpen, setIsShareGameModalOpen] = useState(false)
  const { user } = useUser()

  const navigate = useNavigate()

  const {
    tasks,
    gameData,
    createGame,
    updateGame,
    isOpen,
    handleGameOpen,
    highlightedTaskId,
    shareGameToLibrary,
    deleteFromGameLibrary,
    editorPermissions,
  } = useGame()

  useEffect(() => {
    if (initialGameId == null && gameData?.gameId != null) {
      navigate(
        routes.createGameEditorWithId(
          gameData.gameId.toString(),
          (gameData?.disableNewEditor || user?.preferLegacyEditor) ?? false,
        ),
        {
          replace: true,
        },
      )
    }
    if (gameData?.gameId != null && gameData?.disableNewEditor) {
      navigate(routes.createGameEditorWithId(gameData.gameId.toString(), true), {
        replace: true,
      })
    }
  }, [gameData?.disableNewEditor, gameData?.gameId, initialGameId, navigate, user?.preferLegacyEditor])

  const [isQuickStartWizardOpen, setIsQuickStartWizardOpen] = useState(
    initialGameId == null || shouldOpenWizardOnGameOpen(initialGameId.toString()),
  )
  const [isGameSettingsModalOpen, setIsGameSettingsModalOpen] = useState(false)
  const scrollToPlayerSettings = useRef<boolean>(false)
  const [isBadgeManagerModalOpen, setIsBadgeManagerModalOpen] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState(initialIsGameOpen ? TabType.PLAY : TabType.BUILD)
  const [forceBoardSectionRefresh, setForceBoardSectionRefresh] = useState(0)

  const handleClickTab = useCallback((id: string) => {
    setActiveTab(id as TabType)
  }, [])

  const handleClickGameSettings = (scrollToPlayers: boolean = false) => {
    scrollToPlayerSettings.current = scrollToPlayers
    setIsGameSettingsModalOpen(true)
  }

  const handleClickCloseGameSettings = () => {
    scrollToPlayerSettings.current = false
    setIsGameSettingsModalOpen(false)
  }

  const handleCloseQuickStartWizard = () => {
    setIsQuickStartWizardOpen(false)
  }

  const handleOpenGameSettingsFromWizard = () => {
    setIsQuickStartWizardOpen(false)
    setIsGameSettingsModalOpen(true)
  }

  const switchSettingsToQuickStartWizard = () => {
    scrollToPlayerSettings.current = false
    setIsGameSettingsModalOpen(false)
    setIsQuickStartWizardOpen(true)
  }

  useEffect(() => {
    if (!isQuickStartWizardOpen && initialGameId && shouldOpenWizardOnGameOpen(initialGameId.toString())) {
      setShouldOpenWizardOnGameOpen('')
    }
  }, [initialGameId, isQuickStartWizardOpen])

  const checkShouldContinue = async (boardSettings: GameBoardSettings): Promise<boolean> => {
    if (
      boardSettings.gameBoardType == null ||
      (boardSettings.gameBoardType !== MapType.LIVE && !boardSettings.gameBoards.length)
    ) {
      const shouldContinue = await requestConfirmation({
        title: t('game_editor.create_game_without_board_confirmation_title', 'No game board'),
        text: t(
          'game_editor.create_game_without_board_confirmation_text',
          "You haven't defined a game board to your game. Do you want to continue without a game board?",
        ),
      })
      return shouldContinue
    }
    return true
  }

  const handleSubmitQuickStartWizard = async (data: GameForm) => {
    const shouldContinue = await checkShouldContinue(data.gameBoardSettings)
    if (shouldContinue) {
      const success = await (gameData == null ? createGame(data) : updateGame(data))
      setIsQuickStartWizardOpen(!success)
    }
  }

  const handleSubmitGameSettings = async (data: GameForm, moveTasks?: boolean) => {
    const shouldContinue = await checkShouldContinue(data.gameBoardSettings)
    if (!shouldContinue) {
      return
    }
    if (gameData == null) {
      const success = await createGame(data)
      if (!success) {
        notifyError({
          title: t('game_editor.create_game_error_notification.title', 'Failed to create game'),
          content: t(
            'game_editor.create_game_error_notification.content',
            'An error occurred while creating a new game. Please try again or contact us for support.',
          ),
        })
      } else if (data.advancedSettings.explorationMode && data.gameBoardSettings.gameBoards.length > 1) {
        notifyDoorsAdded({
          title: t('game_editor.create_game_exploration_doors_added.title', 'Doors added'),
          content: t(
            'game_editor.create_game_exploration_doors_added.content',
            'We added doors to all game boards. You can position, edit and delete the doors as you wish.',
          ),
        })
      }
      setIsGameSettingsModalOpen(!success)
    } else {
      const shouldAddDefaultDoors =
        data.advancedSettings.explorationMode &&
        data.gameBoardSettings.gameBoards.length > 1 &&
        data.gameBoardSettings.gameBoards.every((board) => (board.doors ?? []).length === 0)
      const success = await updateGame(data, moveTasks, shouldAddDefaultDoors)
      if (!success) {
        notifyError({
          title: t('game_editor.update_game_error_notification.title', 'Failed to update game'),
          content: t(
            'game_editor.update_game_error_notification.content',
            'An error occurred while updating the game. Please try again or contact us for support',
          ),
        })
      } else if (shouldAddDefaultDoors) {
        notifyDoorsAdded({
          title: t('game_editor.create_game_exploration_doors_added.title', 'Doors added'),
          content: t(
            'game_editor.create_game_exploration_doors_added.content',
            'We added doors to all game boards. You can position, edit and delete the doors as you wish.',
          ),
        })
      }
      setIsGameSettingsModalOpen(!success)
    }
  }

  const handleSetActiveBoard = useCallback(
    (id: number) => {
      setActiveBoardIndex(id)
      //If panorama active map is changed, need to do forced refresh for pannellum to re-render
      if (gameData?.gameBoardSettings.gameBoardType === MapType.PANORAMA) {
        setForceBoardSectionRefresh(Math.random())
      }
    },
    [gameData?.gameBoardSettings.gameBoardType],
  )

  const handleCloseShareGame = useCallback(() => {
    setIsShareGameModalOpen(false)
  }, [])

  const handleToggleShareGame = useCallback(() => {
    setIsShareGameModalOpen((prev) => !prev)
  }, [])

  const handleShareGamePublish = useCallback(
    async (data: ShareGameForm) => {
      const success = await shareGameToLibrary(data)
      if (success) {
        notifySuccess({
          title: t('game_editor.share_game.share_success_notification.title', 'Game shared successfully'),
          content:
            data.shareToPrivate && data.shareToPublic
              ? t(
                  'game_editor.share_game.share_success_notification.text_private_and_public',
                  'The game is visible to other users with access to your organization library and also publicly to all Seppo users.',
                )
              : data.shareToPrivate
              ? t(
                  'game_editor.share_game.share_success_notification.text_private',
                  'The game is visible only to other users with access to your organization library.',
                )
              : t(
                  'game_editor.share_game.share_success_notification.text_public',
                  'The game is visible to all other Seppo users.',
                ),
        })
      } else {
        notifyError({
          title: t('game_editor.share_game.share_error_notification.title', 'Failed to share game'),
          content: t(
            'game_editor.share_game.share_error_notification.content',
            'An error occurred while sharing the game. Please try again or contact us for support',
          ),
        })
      }
      setIsShareGameModalOpen(!success)
    },
    [notifyError, notifySuccess, shareGameToLibrary],
  )

  const handleShareGameDelete = useCallback(async () => {
    const success = await deleteFromGameLibrary()
    if (success) {
      notifySuccess({
        title: t('game_editor.share_game.share_removed_success_notification.title', 'Game removed from library'),
        content: t(
          'game_editor.share_game.share_removed_success_notification.content',
          'Game is no longer available in the game library.',
        ),
      })
    } else {
      notifyError({
        title: t('game_editor.share_game.share_removed_error_notification.title', 'Failed to remove game'),
        content: t(
          'game_editor.share_game.share_removed_error_notification.content',
          'An error occurred while removing the game from library. Please try again or contact us for support',
        ),
      })
    }
    setIsShareGameModalOpen(!success)
  }, [deleteFromGameLibrary, notifyError, notifySuccess])

  useEffect(() => {
    const maxBoardIndex = (gameData?.gameBoardSettings.gameBoards?.length ?? 1) - 1
    if (activeBoardIndex > maxBoardIndex) {
      setActiveBoardIndex(maxBoardIndex)
    }
    // If Game settings has been opened / closed in Panorama game, then the map needs to re-render
    // Otherwise wrong Pannellum instance stays active
    if (gameData?.gameBoardSettings.gameBoardType === MapType.PANORAMA && isGameSettingsModalOpen === false) {
      setForceBoardSectionRefresh(Math.random())
    }
  }, [
    activeBoardIndex,
    gameData?.gameBoardSettings.gameBoardType,
    gameData?.gameBoardSettings.gameBoards?.length,
    isGameSettingsModalOpen,
  ])

  useEffect(() => {
    if (isOpen && preventUserStartGame(user, gameData)) {
      handleGameOpen()
    }
  }, [isOpen, handleGameOpen, gameData, user])

  useEffect(() => {
    if (highlightedTaskId != null) {
      animateNewlyAddedPin(highlightedTaskId)
    }
  }, [highlightedTaskId])

  useEffect(() => {
    if (gameData?.gameBoardSettings.is3D && gameData?.tasks.length < 1){
      notifySuccess({
        title: t('game_editor.3d_game.start_toast.title', "Let'start"),
        timeout: false,
        content: <div>{t(
          'game_editor.3d_game.start_toast.content',
          'Begin by creating your tasks and dragging them to the marked spots on the map. For guidance, take a look at our tutorial on how to create a 3D game!',
        )}<br/><br/><a target='_blank' rel='noreferrer' href='https://seppo.io'>{t('game_editor.3d_game.open_tutorial.button_text', 'Open tutorial')}</a></div>,
      })
    }
  }, [gameData?.gameBoardSettings.is3D, gameData?.tasks.length, notifySuccess])

  return (
    <div className={styles.container}>
      {isQuickStartWizardOpen && (
        <QuickStartWizard
          onClose={handleCloseQuickStartWizard}
          onOpenGameSettings={handleOpenGameSettingsFromWizard}
          onSubmit={handleSubmitQuickStartWizard}
        />
      )}
      <Header
        gameName={gameData?.name}
        hasChat={gameData == null || gameData.advancedSettings.chatEnabled}
        activeTab={activeTab}
        onClickTab={handleClickTab}
        onClickGameSettings={handleClickGameSettings}
        onClickShareGame={handleToggleShareGame}
        isShareGameOpen={isShareGameModalOpen}
        isShareAvailable={!!gameData && !gameData.imported}
        permissions={editorPermissions}
      />
      {!gameData?.gameBoardSettings.is3D && <GlobalNotification isEditor={true} gameId={initialGameId?.toString()} />}
      {initialGameId != null && (
        <EditorSidebar
          activeBoardIndex={activeBoardIndex}
          onSetActiveBoard={handleSetActiveBoard}
          activeTab={activeTab}
          game={gameData}
          tasks={tasks}
          onClickDefineBoards={() => setIsGameSettingsModalOpen(true)}
        />
      )}
      <BoardSection
        activeBoardIndex={activeBoardIndex}
        gameData={gameData}
        isEdit={initialGameId != null}
        forceBoardSectionRefresh={forceBoardSectionRefresh}
      />
      {activeTab === TabType.REPORT && <GameReport gameId={gameData?.gameId ?? null} />}
      {isGameSettingsModalOpen && (
        <GameSettingsModal
          gameData={gameData}
          scrollToPlayerSettings={scrollToPlayerSettings.current}
          permissions={editorPermissions}
          onClose={handleClickCloseGameSettings}
          onSubmit={handleSubmitGameSettings}
          onSwitchToQuickStartWizard={switchSettingsToQuickStartWizard}
          onOpenBadgeManager={() => setIsBadgeManagerModalOpen(true)}
        />
      )}
      {isBadgeManagerModalOpen && (
        <BadgeManagerModal onClose={() => setIsBadgeManagerModalOpen(false)} badges={gameData?.badges ?? []} />
      )}
      {isShareGameModalOpen && (
        <ShareGameModal
          gameData={gameData}
          onClose={handleCloseShareGame}
          onSubmit={handleShareGamePublish}
          onDelete={handleShareGameDelete}
        />
      )}
      <HelpMenu isChatOpen={isChatOpen} />
      {gameData?.advancedSettings.explorationMode && <BoardsOverview onActivateBoardToEditor={setActiveBoardIndex} />}
    </div>
  )
}
