import { FORM_ERROR } from 'final-form'
import React from 'react'
import { PaymentMethod } from '../api/businessSettingsTypes'
import { ExerciseType, LibrarySource, ShareType, TaskIconType } from '../api/gameTypes'
import { MessageTypeEnum } from '../api/messagingTypes'
import { GameFilters } from '../contexts/OwnGamesContextProvider'
import { GameBoardSettings, GameForm } from '../pages/GameEditor/types'

export enum GameStatus {
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT', // not open, no answers
  IN_PROGRESS = 'IN_PROGRESS', // open
  PAUSED = 'PAUSED', // not open, has answers
  COMMUNITY = 'COMMUNITY',
  SPONSORED = 'SPONSORED',
  LIBRARY = 'LIBRARY',
  TEMPLATE = 'TEMPLATE',
  EXPIRED = 'EXPIRED',
}

export enum SecondaryGameStatus {
  DOWNLOADED = 'DOWNLOADED', // own game imported from community or library
  PUBLISHED = 'PUBLISHED', // own game that was shared
}

export type TGameRating = {
  average: string
  count: number
}

export type TGameCard = {
  id: string
  exercisesCount?: number
  gameName: string
  gameOwners: string[]
  modifiedAt: string
  status: GameStatus
  disableNewEditor: boolean
  secondaryStatus?: SecondaryGameStatus
  librarySource: LibrarySource
  gamePackageSource?: GamePackage
  thumbnail?: string
  archivedAt?: string
  mapsCount?: number
  storyHtml?: string
  rulesHtml?: string
  showStoryAndRules?: boolean
  language?: string
  ages?: string[]
  subjects?: string[]
  instructionsForTeacherHtml?: string
  description?: string
  rating?: TGameRating
  downloaded?: number
  tags?: string[]
  playersCount?: number
  expired: boolean
  validUntil?: string
  has_activation_code: boolean
}

export enum GameAction {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  COPY = 'COPY',
  IMPORT = 'IMPORT',
  DELETE = 'DELETE',
  DOWNLOAD = 'DOWNLOAD',
  DOWNLOADMODAL = 'DOWNLOADMODAL',
}

export type TGameItem = TGameCard & {
  onContextMenuClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void
  onGameEditorClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void
  onAddClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void
}

export type GamesFilterSettings = DeepMappedType<GameFilters, true>

export type GameMap = {
  url: string
  dimensions?: {
    x: number
    y: number
  }
}

export type GameMapsAndExercises = {
  gameId: number
  tasks: Task[]
  maps: GameMap[]
  noPointsGame: boolean
}

export type GameCreatorFilterOption = {
  id: number
  name: string
}

export type NotificationSettings = {
  messages?: string
  answers?: string
  instantEnabled: boolean
  summaryEnabled: boolean
  summarySendTime?: string
  emails?: string[]
}

export type Badge = {
  id: number
  imageUrl: string
  name: string
}

export type GameAdvancedSettings = {
  emailRequired: boolean
  teamMemberNamesRequired: boolean
  chatEnabled: boolean
  allowBranching: boolean
  gpsEnabled: boolean
  explorationMode: boolean
  levelsEnabled: boolean
  orderingEnabled: boolean
  allowPlayersToImproveAnswers: boolean
  noPointsGame: boolean
  hideScoreboard: boolean
  askPlayerFeedback: boolean
  badgesEnabled: boolean
  publicLinkEnabled: boolean
  publicLinkSecret?: string
}

export type LevelCriteria = {
  name: string
  points: number
  completedTasks: number
  defaultBoardIndex?: number
  isActive: boolean // used only by UX3 FE to indicate whether a level has been added yet
}

export type Game = {
  gameId: number
  open: boolean
  name: string
  disableNewEditor: boolean
  description?: string
  language?: string
  ages?: string[]
  keywords?: string[]
  topics?: string[]
  tasks: Task[]
  storyHtml?: string
  rulesHtml?: string
  showStoryAndRules?: boolean
  instructionsForTeacherHtml?: string
  gameBoardSettings: GameBoardSettings
  notificationSettings?: NotificationSettings
  advancedSettings: GameAdvancedSettings
  pinCode?: string
  theme?: Theme
  levelsCriteria?: LevelCriteria[]
  validUntil?: string
  exported: boolean
  imported: boolean
  rootTaskId?: number
  badges: Badge[]
  editRestricted: boolean
}

export type LibraryGameDetails = {
  id: number
  name: string
  description: string
  creator: string
  email: string
  phone: Maybe<string>
  shareType: ShareType
  masterOwnerId: number
  businessId: Maybe<number>
}

export enum RoleType {
  Player = 'PLAYER',
  Instructor = 'INSTRUCTOR',
  Evaluator = 'EVALUATOR',
  StudentInstructor = 'STUDENT_INSTRUCTOR',
}

export type Instructor = {
  id: number
  name: string
  nickName: string
  role: RoleType.Instructor
  teamMemberNames: string
  validUntil: string
  playerCode: string
}

export type Evaluator = {
  id: number
  name: string
  nickName: string
  role: RoleType.Evaluator
  teamMemberNames: string
  validUntil: string
  playerCode: string
}

export type StudentInstructor = {
  id: number
  name: string
  nickName: string
  role: RoleType.StudentInstructor
  teamMemberNames: string
  validUntil: string
  playerCode: string
}

export type Player = {
  id: number
  name: string
  nickName: string
  playerCode: string
  teamMemberNames: string
  email: string
  points: number
  role: RoleType.Player
  deleted: boolean
  isEmailTrusted: boolean
  startingTask: Maybe<number>
  badges: Badge[]
}

export type GamePeople = {
  players: Player[]
  instructors: Instructor[]
  evaluators: Evaluator[]
  studentInstructors: StudentInstructor[]
}

export type PlayerScore = {
  playerId: number
  name: string
  points: number
  position: number
}

export enum AllowedOption {
  COLLABORATION = 'COLLABORATION',
  ADVANCED_MODE = 'ADVANCED_MODE',
  TREE_MODEL = 'TREE_MODEL',
  PLAYER_FEEDBACK = 'PLAYER_FEEDBACK',
  GAME_PACKAGES = 'GAME_PACKAGES',
  SPONSORED_GAMES_REPORTING = 'SPONSORED_GAMES_REPORTING',
  ALLOW_NEW_PLAYER_UI = 'ALLOW_NEW_PLAYER_UI',
  ADD_PLAYERS_BY_EMAIL = 'ADD_PLAYERS_BY_EMAIL',
  PLAYER_REPORTING = 'PLAYER_REPORTING',
  LEVELS = 'LEVELS',
  MULTIMAPS = 'MULTIMAPS',
  NO_POINTS_GAME = 'NO_POINTS_GAME',
  PLAYERCONTINUESENTANS = 'PLAYERCONTINUESENTANS',
  '360_MAPS' = '360_MAPS',
  TEACHER_REPORTING = 'TEACHER_REPORTING',
  EXPORT_RESULTS = 'EXPORT_RESULTS',
  ORDERED_EXERCISES = 'ORDERED_EXERCISES',
  CUSTOM_CHAT = 'CUSTOM_CHAT',
  GRADINGLICENCE = 'GRADINGLICENCE',
  PUBLICSUMMARY = 'PUBLICSUMMARY',
  STUDENTLICENCE = 'STUDENTLICENCE',
  SUMMARY_DOWNLOAD = 'SUMMARY_DOWNLOAD',
  P2P_MESSAGING = 'P2P_MESSAGING',
  EXERCISE_CODE = 'EXERCISE_CODE',
  PRIVATE_LIBRARY = 'PRIVATE_LIBRARY',
  MISSINGWORDEXERCISE = 'MISSINGWORDEXERCISE',
  MATCHPAIRS = 'MATCHPAIRS',
  CORPORATE_LIBRARY = 'CORPORATE_LIBRARY',
  ALLOW_NEW_EDITOR = 'ALLOW_NEW_EDITOR',
}

type SenderOrReceiver = {
  name: string
  id: number
}

export type TMessage = {
  text: string
  from: SenderOrReceiver
  id: number
  createdAt: Date
  to: Maybe<SenderOrReceiver>
  isGeneralMessage: boolean
  msgType: Maybe<MessageTypeEnum>
}

export enum Language {
  // TODO: Exported from Smartfeets. Should the list be replaced with new one?
  SUOMI = 'fi',
  ENGLISH = 'en',
  SVENSKA = 'sv',
  NEDERLANDS = 'nl',
  PORTUGUESE = 'pt',
  SPANISH = 'es',
  DEUTSCH = 'de',
  NORSK = 'nb',
}

export enum Report {
  BUSINESS = 'BUSINESS',
  GROUP = 'GROUP',
  TEACHER = 'TEACHER',
  SPONSORED_GAMES = 'SPONSORED_GAMES',
  PLAYER = 'PLAYER',
}

export type Business = {
  id: number
  name: string
  validUntil?: string
  country: string
  industry: string
  loginCount: number //For some businesses it is limited how many players they can have and the current count is shown in old dashboard
}

export type Tag = {
  id: number
  label: string
  businessId: number
  playerCount?: number
}

export type Theme = {
  themeLogoUrl: string
  themeAllowedLanguages: string //List of lang codes
  themeMainColor: string //#aabbcc or rgb(1,2,3)
  themeSecondaryColor: string //#aabbcc or rgb(1,2,3)
  themeFaviconUrl: string
  themeTabName: string
}

export type ThemeSettings = {
  logoUrl: string
  faviconUrl: string
  colorPrimary: string
  colorSecondary: string
  tabName: string
  allowedLanguages?: string
  backgroundImageUrl?: string
  rawCodeSectionTop?: string //May contain for example custom SSO button
  rawCodeSectionBottom?: string //May contain added logos, or custom privacy link specific to WL etc
  loginLanguage?: string
  loginHideLanguageSelection?: boolean
  loginHideTeacherArea?: boolean
  loginBottomLogoUrl?: string
  timeStamp?: string
}

export type GamePackage = {
  id: number
  name?: string
  validUntil?: string
  defaultInstructor?: string
}

export type DashboardMessage = {
  id: number
  title: string
  message: string
  buttonLabel: string
  buttonLink?: string
}

export type TUser = {
  id: number
  name: string //This user's unique login/username, which is often the same as email
  userInitials: string
  email: string | null
  language: string //en | fi | sv ...
  chatName: string
  contactName: string
  dashboardMessage?: DashboardMessage
  hasPlayerAccounts: boolean
  isBusinessAdmin: boolean
  isBusinessGroupMaster: boolean
  isBusinessGroupTechMaster: boolean
  isBusinessSuperUser: boolean
  isStudentTeacher: boolean
  isGradingInstructor: boolean
  isSponsoredUser: boolean
  hasValidSponsored: boolean
  isTrial: boolean
  isCorporate: boolean
  isEducation: boolean
  hasOrgLibrary: boolean
  hasCommunity: boolean
  hasTemplates: boolean
  allowUseNewEditor: boolean
  preferLegacyEditor: boolean
  passwordChanged: boolean
  termsAccepted: boolean
  hasTeacherReporting: boolean
  allowedOptions: AllowedOption[] //List of additional allowed options, we could put them also as separate fields
  activeBusiness: Business
  primaryBusinessId: number
  businesses: Business[]
  tags: Tag[] //List of tags, related to player accounts feature
  createGame: Boolean
  createdAt: string
  theme: Theme
  reports: Report[] // list of reports visible to user
  businessReportUrl: string
  useOldDashboard: boolean
  hasNewPaymentInfo: boolean
  ux3Shown: boolean
  seenMessages?: string
  gamePackages?: GamePackage[]
}

export type UserDataField = keyof TUser

//Below is copied from native app util/types.ts
export type Maybe<T> = T | null

export type Try<T, E extends Error = Error> = Success<T> | Failure<E>

export interface DateTime extends String {
  // '2019-10-17T12:43:45.044Z'
  _dateTime: never
}

export interface Success<T>
  extends Readonly<{
    value: T
    success: true
  }> {}

export interface Failure<E>
  extends Readonly<{
    error: E
    success: false
  }> {}

export type Either<L, R> = Left<L> | Right<R>

export interface Left<T>
  extends Readonly<{
    tag: 'left'
    value: T
  }> {}

export interface Right<T>
  extends Readonly<{
    tag: 'right'
    value: T
  }> {}

export function toSuccess<T>(value: T): Success<T> {
  return {
    success: true,
    value,
  }
}

export function toFailure<E>(error: E): Failure<E> {
  return {
    error,
    success: false,
  }
}

// NOTE: basic types and arrays of basic types on type T are mapped to K
// objects and arrays of objects on type T are either mapped to K
// or map each of their properties recursively
export type DeepMappedType<T, K> = {
  [Property in keyof T]: T[Property] extends object
    ?
        | (T[Property] extends Array<any>
            ? T[Property] extends Array<object>
              ? DeepMappedType<T[Property], K> | K
              : K
            : DeepMappedType<T[Property], K>)
        | K
    : K
}

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

export type ObjectAny = {
  [key: string]: any
}

export type ObjectGeneric<T> = {
  [key: string]: T
}

export type FormErrorType<T> = DeepPartial<DeepMappedType<T, string> & { [FORM_ERROR]: string }>

export type FormFieldProps<T extends ObjectAny> = Omit<T, 'name' | 'label'> & {
  name: string
  label: string | JSX.Element
  srOnlyLabel?: boolean
  id?: string
  fieldContainerClassName?: string
  inline?: boolean
  placeholder?: string
  validateOnlyIfDirty?: boolean
  errorClassName?: string
  controlWithLabelClassName?: string
  labelClassName?: string
  parse?: (value: any, name: string) => any
  format?: (value: any, name: string) => any
}

//TODO: We could have separate types for ReceivedAnswer per Task type. For now just this one
export type ReceivedAnswer = {
  id: number
  exerciseId: number
  userId: number
  feedbackHtml: Maybe<string>
  feedbackMessage: Maybe<string>
  feedbackImageUrl: Maybe<string>
  selfieUrl: Maybe<string>
  selfieMessage: Maybe<string>
  answer: string //Structure differs for exercise types
  answerIndex: Maybe<number> //Some answers to multichoice/poll task have the selected answer as index of the selected option
  answerIndexes: Maybe<number[]> //Poll answers have the indexes of the selected option
  state: AnswerStateEnum
  points: number
  completedAt: string
  hasMorePreviousAnswers: boolean
  previousAnswers: ReceivedAnswer[]
  badge?: Badge
}

export type SubtaskBase = {
  id?: number
  tempId?: number
  description: string
  answers?: ReceivedAnswer[]
  maxPoints: number
}

export type TaskAdvanced = {
  isFlash?: boolean
  requirePictureOrSelfie?: boolean
  hasTimeLimit?: boolean
  timeLimitSeconds?: number
  hasLockCode?: boolean
  lockCode?: string
  lockClue?: string
  hasProximityLock?: boolean
  requiredProximity?: string
  isHidden?: boolean
  hasCustomIconType?: boolean
  iconType?: TaskIconType
  hasAutomaticBadge?: boolean
  automaticBadgeId?: number
  automaticBadgePoints?: number
}

export type Task = {
  id: number
  mapIndex?: number
  name: string
  x: number
  y: number
  subtasks: Subtask[]
  advanced: TaskAdvanced
  type: ExerciseType
  pointsSum?: number
  isOpen: boolean
  level?: number
  order?: number
  flashOrder?: number
  isGoalTask?: boolean
  order3D?: number
}

export type TaskConnection = {
  fromId: number
  toId: number
}

export enum CreativeAnswerOption {
  Text = 'Text',
  Images = 'Images',
  Video = 'Video',
  Audio = 'Audio',
}

export enum PointsType {
  Evaluate = 'Evaluate',
  Automatic = 'Automatic',
}

export enum Currencies {
  Euro = '€',
}

export type CreateAndCommentSubtaskData = {
  test: string
}

export type CreateAndCommentSubtask = SubtaskBase & {
  type: ExerciseType.CollaborationExercise
  data: CreateAndCommentSubtaskData
}

export type MatchPairsAnswerSide = {
  text: string
  imageUrl?: string
}

export type MatchPairsAnswerOption = {
  points?: number
  left: MatchPairsAnswerSide
  right: MatchPairsAnswerSide
}

export type MatchPairsSubtaskData = {
  allAnswersHaveSamePoints?: boolean
  allAnswersPoints?: number
  answers: MatchPairsAnswerOption[]
}

export type MatchPairsSubtask = SubtaskBase & {
  type: ExerciseType.CombineExercise
  data: MatchPairsSubtaskData
}

export type CreativeSubtaskData = {
  answerOptions: CreativeAnswerOption[]
  pointsType: PointsType
  pointsForAnswer: number
  autoScoreOption?: string
  autoScorePercentage?: number
  hasEvaluationGuideline: boolean
  evaluationGuideline?: string
  hasAutomatedFeedback: boolean
  automatedFeedback?: string
}

export type CreativeSubtask = SubtaskBase & {
  type: ExerciseType.CreativeExercise
  data: CreativeSubtaskData
}

export type MissingWordAnswerOption = {
  word: string
  points?: number
  ux3id: string // used for managing words in FE, not needed for BE
}

export type MissingWordSubtaskData = {
  allAnswersHaveSamePoints?: boolean
  allAnswersPoints?: number
  answers: MissingWordAnswerOption[]
}

export type MissingWordSubtask = SubtaskBase & {
  type: ExerciseType.MissingWordExercise
  data: MissingWordSubtaskData
}

export type MultipleChoiceAnswerOption = {
  text: string
  points?: number
  imageUrl?: string
  isCorrectAnswer?: boolean
  hasFeedback?: boolean
  feedback?: string
  nextTaskId?: string | null
}

export type MultipleChoiceSubtaskData = {
  allAnswersHaveSamePoints?: boolean
  allAnswersPoints?: number
  answers: MultipleChoiceAnswerOption[]
}

export type MultipleChoiceSubtask = SubtaskBase & {
  type: ExerciseType.MultichoiceExercise
  data: MultipleChoiceSubtaskData
}

export type CheckboxAnswerOption = {
  text: string
  points?: number
  imageUrl?: string
  isCorrectAnswer?: boolean
}

export type CheckboxSubtaskData = {
  allAnswersHaveSamePoints?: boolean
  allAnswersPoints?: number
  answers: CheckboxAnswerOption[]
  hasAutomatedFeedback: boolean
  automatedFeedback?: string
}

export type CheckboxSubtask = SubtaskBase & {
  type: ExerciseType.PollExercise
  data: CheckboxSubtaskData
}

export type Subtask =
  | CheckboxSubtask
  | CreateAndCommentSubtask
  | CreativeSubtask
  | MatchPairsSubtask
  | MissingWordSubtask
  | MultipleChoiceSubtask

export enum AnswerStateEnum {
  GRADED = 'graded',
  READY = 'ready',
  IN_PROGRESS = 'in_progress',
  TIME_FINISHED = 'time_finished',
  REVISION = 'revision',
}

export type PlayerAnswer = {
  answer: ReceivedAnswer
  subtask: Subtask
}

export type Evaluation = {
  player: Player
  task: Omit<Task, 'subtasks'> & { subtasksCount: number }
  answers: PlayerAnswer[]
  state: AnswerStateEnum
  totalPoints: number
}

export type CardPaymentInfo = {
  paymentType: PaymentMethod.CARD
  maskedNumber: string
  validUntil: string
  email: string
}

export type InvoicePaymentInfo = {
  paymentType: PaymentMethod.INVOICE
  city: string
  country: string
  einvAddress: string
  einvOperator: string
  email: string
  postalCode: string
  streetAddress: string
}

export type PaymentInfo = CardPaymentInfo | InvoicePaymentInfo

export type NewBusinessInfo = {
  autoRenew: boolean
  contactPerson: string
  licenceCount: number
  nextDateWhenLicencesFreed?: string
  nextInvoiceDate: string
  nextInvoicePrice?: number
  paymentInfo?: PaymentInfo
  remainingUnusedSeats: number
  renewCycle: number
  usedPinSeats: number
  usedPlayerAccountSeats: number
  pinSeatHolders: string
  emailSeatHolders: string
  vat?: string
}

export type BusinessInfo = {
  validUntil: string
  totalPlayersCount: number
  maxMembers: number | null
  maxPlayers: number | null
  newBusinessInfo?: NewBusinessInfo
}

export type TBusinessSettings = {
  businessInfo: BusinessInfo
  members: TMember[]
}

export type TMember = {
  id: number
  name: string
  email: string
  role: MemberRole
  lastActive?: string
}

export type TAddMember = Omit<TMember, 'id'>

export enum MemberRole {
  ADMIN = 'ADMIN',
  INSTRUCTOR = 'INSTRUCTOR',
  // roles below can be assigned only by Seppo admin
  SUPERUSER = 'SUPERUSER',
  SUPERUSER_ADMIN = 'SUPERUSER_ADMIN',
}

export type FnAny = (...args: any) => any

export type ApiResponse = {
  success: boolean
  errorMessage?: string
  msg?: string
}

export type PlayerAccount = {
  id: number
  email: string
  invitedBy: Maybe<number>
  nickName: string
  tags: Tag[]
}

// only actions that are not available to some are included, this is not a list of all actions
type EditorPermissionActions = {
  accessPeopleModal: boolean
  accessChatModal: boolean
  addPlayers: boolean
  dashboardLink: boolean
  generalSettingsLink: boolean
  instructors: boolean
  modifyGameSettings: boolean
  modifyPlayers: boolean
  shareGame: boolean
  startGame: boolean
  storyAndRules: boolean
  tasks: boolean
  flashTasks: boolean
}

type EditorPermissionGameSettings = DeepPartial<DeepMappedType<GameForm, boolean>>

export type EditorPermissions = {
  actions: EditorPermissionActions
  gameSettings: boolean | EditorPermissionGameSettings
}

// type declaration to allow access to shareToMicrosoftTeams and google classrooms share functions
declare global {
  interface Window {
    shareToMicrosoftTeams: any
    gapi: any
  }
}
