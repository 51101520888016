import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SingleValue } from 'react-select'
import { Select, SelectOption } from '../../common/components/Select/Select'
import { LANGUAGE_SELECT_DEFAULT_OPTION, LANGUAGE_SELECT_OPTIONS } from '../../common/constants'
import { HelpMenu } from '../../composites/HelpMenu/HelpMenu'
import styles from './Login.module.css'
import { InstructorOrPlayer } from './components/InstructorOrPlayer'
import { LoginForm } from './components/LoginForm'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { useTheme } from '../../contexts/ThemeContext'

export const Login: React.FC = () => {
  const [stepIndex, setStepIndex] = useState(0)
  const [autofocusEnterCode, setAutofocusEnterCode] = useState(false)
  const { t, i18n } = useTranslation()
  const { state } = useLocation()
  const { loginLanguage, loginHideLanguageSelection } = useTheme()

  const [defaultLanguageOption] = useState(() => {
    return LANGUAGE_SELECT_OPTIONS.find((option) => option.value === i18n.language) ?? LANGUAGE_SELECT_DEFAULT_OPTION
  })

  useEffect(() => {
    if (state != null && state.stepIndex != null) {
      setStepIndex(state.stepIndex)
    }
  }, [state])

  const handleClickNext = () => {
    setStepIndex(1)
  }

  const handleClickPrev = (enterCode?: boolean) => {
    if (enterCode) {
      setAutofocusEnterCode(true)
    } else {
      setAutofocusEnterCode(false)
    }
    setStepIndex(0)
  }

  const handleChangeLanguage = (selectOption: SingleValue<SelectOption>) => {
    i18n.changeLanguage(selectOption?.value)
  }

  return (
    <div className={styles.pageContainer}>
      <div className={styles.languageContainer}>
        {!loginHideLanguageSelection && (
          <Select
            options={LANGUAGE_SELECT_OPTIONS}
            defaultValue={
              loginLanguage
                ? LANGUAGE_SELECT_OPTIONS.find((option) => option.value === loginLanguage) ?? defaultLanguageOption
                : defaultLanguageOption
            }
            onChange={handleChangeLanguage}
            className={styles.languageSelect}
          />
        )}
      </div>
      <div className={styles.loginWizard}>
        {stepIndex === 0 && (
          <InstructorOrPlayer
            shouldMirror={false}
            autofocusEnterCode={autofocusEnterCode}
            onClickContinueWithSignup={handleClickNext}
          />
        )}
        {stepIndex === 1 && <LoginForm shouldMirror={false} onClickBack={handleClickPrev} />}
      </div>
      <p className={styles.learnMoreBottomOffset}>
        {t('login.new_to_seppo', 'New to Seppo?')}{' '}
        <a rel='noreferrer' href='https://seppo.io/'>
          {t('login.learn_more', 'Learn more here')}
        </a>
      </p>
      <div className={styles.hideOnSmallScreen}>
        <HelpMenu
          buttonClassName={classNames(styles.invertedHelpButton, styles.hideOnSmallScreen)}
          shouldMirror={false}
        />
      </div>
    </div>
  )
}
