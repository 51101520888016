import classNames from 'classnames'
import arrayMutators from 'final-form-arrays'
import i18next from 'i18next'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { Trans, useTranslation } from 'react-i18next'
import { AddEntityFieldButton } from '../../../../common/components/Form/AddEntityFieldButton/AddEntityFieldButton'
import { InputFormField } from '../../../../common/components/Form/InputFormField/InputFormField'
import { SelectFormField } from '../../../../common/components/Form/SelectFormField/SelectFormField'
import { PopupActionMenu, PopupActionMenuItem } from '../../../../common/components/PopupMenu/PopupActionMenu'
import { ModalPortal } from '../../../../common/components/Portal/Portal'
import { SelectVariant } from '../../../../common/components/Select/Select'
import { Button } from '../../../../common/components/button/Button'
import { CloseButton } from '../../../../common/components/button/CloseButton'
import { getIcon } from '../../../../common/components/icons/utils'
import { ThreeDots } from '../../../../common/components/loaders/ThreeDots/ThreeDots'
import { useFadeAnimation } from '../../../../hooks/FadeAnimation/useFadeAnimation'
import { useDisableBodyScroll } from '../../../../hooks/useDisableBodyScroll'
import { useTrapFocus } from '../../../../hooks/useTrapFocus'
import { DeepPartial, FormErrorType, MemberRole, TAddMember } from '../../../../types/commonTypes'
import { safeIsNullOrEmpty } from '../../../../util/string'
import { getMemberRoleOptions } from '../helpers'
import styles from './AddMembersModal.module.css'
import Papa from 'papaparse'
import { useCallback, useState } from 'react'
import { ShowAllMembersButton } from '../../../../common/components/Form/ShowAllMembersButton/ShowAllMembersButton'

export interface Member {
  name: string
  email: string
  role: MemberRole
  id: string
}

type AddMembersModalProps = {
  organizationName: string
  onSubmit: (values: AddMembersFormValues) => Promise<boolean>
  onClose: () => void
}

export type AddMembersFormValues = {
  members: Member[]
}

const validate = (values: DeepPartial<AddMembersFormValues>): FormErrorType<AddMembersFormValues> => {
  return {
    members: (values.members || []).map((member) => ({
      name: safeIsNullOrEmpty(member?.name) ? i18next.t('validation_errors.required', 'Required') : undefined,
      email: safeIsNullOrEmpty(member?.email) ? i18next.t('validation_errors.required', 'Required') : undefined,
    })),
  }
}

const initialValues = { members: [{ name: '', email: '', role: MemberRole.INSTRUCTOR, id: '' }] }

export const AddMembersModal: React.FC<AddMembersModalProps> = ({ onSubmit, organizationName, onClose }) => {
  const { t } = useTranslation()
  const trapFocusRef = useTrapFocus<HTMLDivElement>()
  useDisableBodyScroll()
  const [values, setValues] = useState<AddMembersFormValues | null>(null)
  const [showAllMembers, setShowAllMembers] = useState(true)
  const [hasCsvMembers, setHasCsvMembers] = useState(false)
  const [memberListLength, setMemberListLength] = useState(0)

  const { fadeTransition, closeWithDelay } = useFadeAnimation()

  const onCloseInternal = () => {
    closeWithDelay(350, onClose)
  }

  const onSubmitInternal = async (values: AddMembersFormValues) => {
    const isSuccess = await onSubmit(values)
    if (isSuccess) {
      onCloseInternal()
    }
  }

  const handleSetValues = (member: Member) => {
    setHasCsvMembers(true)
    setValues((prevValues) => (prevValues ? { members: [...prevValues.members, member] } : { members: [member] }))
  }

  const validateAndAddCSVData = useCallback((data: any) => {
    console.log(data)
    console.log(data instanceof Array)
    const memberKeys = ['name', 'email', 'role']
    if (data.length > 3) {
      setShowAllMembers(false)
    }
    if (data instanceof Array) {
      const hasKeys = data.every((member) => {
        if (memberKeys.every((memberKey) => member.hasOwnProperty(memberKey))) {
          if (
            member.role === 'ADMIN' ||
            member.role === 'INSTRUCTOR' ||
            member.role === 'SUPERUSER' ||
            member.role === 'SUPERUSER_ADMIN'
          ) {
            const newMember: Member = {
              name: member.name,
              email: member.email,
              role: member.role,
              id: member.id ? member.id : '',
            }
            handleSetValues(newMember)
            return true
          }
        }
        return false
      })
      console.log(hasKeys)
      if (hasKeys) return true
    }
    return false
  }, [])

  console.log(values)
  const parseMemberCSV = useCallback(
    (data: any): Member[] | void => {
      const parsed = Papa.parse<Member>(data, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (results) => {
          if (results.errors.length) {
            console.error('Errors while parsing:', results.errors)
          } else if (results.data) {
            validateAndAddCSVData(results.data)
          } else {
          }
        },
      })
      return parsed
    },
    [validateAndAddCSVData],
  )

  const onAddCSV = (e: any) => {
    e.preventDefault()

    parseMemberCSV(e.target.files[0])
  }

  return (
    <ModalPortal>
      <div className={fadeTransition}>
        <div className={styles.backdrop}>
          <div className={styles.container} ref={trapFocusRef}>
            <div className={styles.content}>
              <div className={styles.header}>
                <div className={styles.headerTitle}>
                  <span>{getIcon('settingsGradient')}</span>
                  <span className='medium'>
                    {t('settings_organization.members.add_members.title', {
                      organization_name: organizationName,
                      defaultValue: "Add user to '%{organization_name}' organization",
                    })}
                  </span>
                </div>
                <CloseButton autoFocus onClick={onCloseInternal} />
              </div>
              <div className={classNames(styles.description, 'medium')}>
                <Trans i18nKey='settings_organization.members.add_members.description' components={{ 1: <br /> }}>
                  {
                    'Adding members to your organisation allows them to create and share content. <br/> <br/>When uploading an Excel or CSV file, ensure the first column has member names, the second has emails, the third has member IDs (leave this column empty if you do not wish to use it), and the fourth contains roles.'
                  }
                </Trans>
              </div>
              <div className={styles.csvButtonContainer}>
                <form>
                  <label className={styles.csvButton}>
                    <input
                      type={'file'}
                      id={'csvFileInput'}
                      accept={'.csv'}
                      onChange={onAddCSV}
                      style={{ display: 'none' }}
                    />
                    {t('settings_organization.members.add_members.button_text.upload_csv', 'Upload CSV')}
                  </label>
                </form>
              </div>
              <Form<AddMembersFormValues>
                onSubmit={onSubmitInternal}
                validate={validate}
                mutators={{
                  ...arrayMutators,
                }}
                initialValues={values ? values : initialValues}
              >
                {({ handleSubmit, form, submitting }) => (
                  <form onSubmit={handleSubmit}>
                    {submitting && (
                      <>
                        <div className={styles.loaderBackgroundOverlay} />
                        <div className={styles.loaderContainer}>
                          <ThreeDots />
                        </div>
                      </>
                    )}
                    <div className={styles.mainFormContainer}>
                      <div className={styles.rowsContainer}>
                        <FieldArray name='members'>
                          {({ fields }) =>
                            fields.map(
                              (name, index) =>
                                (index < 3 || showAllMembers) && (
                                  <div key={`member_${index}`} className={styles.addMemberRow}>
                                    <InputFormField
                                      name={`${name}.name`}
                                      label={t('settings_organization.members.add_members.labels.name', 'Name')}
                                      placeholder={t(
                                        'settings_organization.members.add_members.placeholders.name',
                                        'Type name',
                                      )}
                                    />
                                    <InputFormField
                                      name={`${name}.email`}
                                      type='email'
                                      label={t('settings_organization.members.add_members.labels.email', 'Email')}
                                      placeholder={t(
                                        'settings_organization.members.add_members.placeholders.email',
                                        'Enter E-mail',
                                      )}
                                    />
                                    <div className={styles.lastColumn}>
                                      <SelectFormField
                                        name={`${name}.role`}
                                        label={t('settings_organization.members.add_members.labels.role', 'Role')}
                                        options={getMemberRoleOptions(t)}
                                        variant={SelectVariant.XLarge}
                                      />
                                      {fields?.length && fields.length > 1 && (
                                        <div className={styles.actionMenu}>
                                          <PopupActionMenu id='add-members-action-menu'>
                                            <PopupActionMenuItem
                                              icon='trash'
                                              text={t('common.delete', 'Delete')}
                                              onClick={() => fields.remove(index)}
                                            />
                                          </PopupActionMenu>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ),
                            )
                          }
                        </FieldArray>
                      </div>
                      {showAllMembers && (
                        <AddEntityFieldButton
                          onClick={() => form.mutators.push('members', { role: MemberRole.INSTRUCTOR })}
                          disabled={submitting}
                        >
                          {t('settings_organization.members.add_members.button_text.add_member', 'Add user')}
                        </AddEntityFieldButton>
                      )}
                      {!showAllMembers && (
                        <ShowAllMembersButton onClick={() => setShowAllMembers(true)}>
                          {t('settings_organization.members.add_members.button_text.show_all', 'Show all')}
                        </ShowAllMembersButton>
                      )}
                    </div>
                    <div className={styles.footer}>
                      <Button variant='outline-normal' onClick={onCloseInternal} disabled={submitting}>
                        {t('settings_organization.members.add_members.button_text.cancel', 'Cancel')}
                      </Button>
                      <Button type='submit' disabled={submitting}>
                        {t('settings_organization.members.add_members.button_text.save', 'Save')}
                      </Button>
                    </div>
                  </form>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </ModalPortal>
  )
}
