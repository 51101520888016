import { getIcon } from '../../icons/utils'
import styles from './ShowAllMembersButton.module.css'
import { PropsWithChildren } from 'react'

type ShowAllMembersButtonProps = {
  onClick: () => void
  disabled?: boolean
}

export const ShowAllMembersButton: React.FC<PropsWithChildren<ShowAllMembersButtonProps>> = ({
  onClick,
  disabled,
  children,
}) => {
  return (
    <button type='button' onClick={onClick} className={styles.button} disabled={disabled}>
      <span className={styles.iconContainer}>{getIcon('plus')}</span>
      <span className='medium'>{children}</span>
    </button>
  )
}
